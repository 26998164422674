import { Injectable } from '@angular/core';
import { LocalNotifications } from '@ionic-native/local-notifications/ngx';

@Injectable({ providedIn: 'root' })
export class NotificationOption {
  constructor(
    private localNotifications: LocalNotifications
  ) { }

  /**
   * 通知する。
   */
  public notification(titleData?: string, textData?: string) {
    this.localNotifications.schedule({
      title: titleData == null ? "" : titleData,
      text: textData == null ? "" : textData
    });
  }
}
