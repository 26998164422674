import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiResponse } from './models/api-response';
import { MaintUser } from './models/maint-user';
import { GenericItem } from './models/generic-item';
import { ServicerProvider } from './servicer';

const BASE_URL = 'account';

/**
 * [ユーザ]ユーザ のプロバイダー.
 */
@Injectable()
export class UserAccoountServiceProvider {

  constructor(
    private servicer: ServicerProvider
  ) { }

  /**
   * ユーザ取得処理.
   * 
   * @param id ユーザID
   * @returns Observable<MaintUser>
   */
   get(id: number): Observable<GenericItem<MaintUser>> {
    const url = `${ BASE_URL }/${ id }`;
    return this.servicer.get<GenericItem<MaintUser>>(url);
  }

  /**
   * ユーザ保存処理.
   *
   * @param data 入力データ
   * @returns Observable<ApiResponse>
   */
  patch(data: MaintUser): Observable<ApiResponse> {
    const url = `${ BASE_URL }/${ data.id }`;
    return this.servicer.patch<MaintUser, ApiResponse>(url, data);
  }

  /**
   * 顔認証登録.
   *
   * @param data FaceRegist
   * @returns Observable<ApiResponse>
   */
  postFace(userId: number, file: Blob): Observable<ApiResponse> {
    const formData = new FormData();
    formData.append('file', file);
    const url = `${ BASE_URL }/${ userId }/face`;
    return this.servicer.postFormData<ApiResponse>(url, formData);
  }
}
